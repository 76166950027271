<template>
  <div
    class="
      flex flex-wrap gap-10 pb-14 pt-6
      md:flex-no-wrap
      lg:flex-no-wrap
      xl:flex-no-wrap
    "
  >
    <div class="flex flex-col gap-10 w-full md:w-5.5/10">
      <Cards
        @select-card="setSelectedCard"
      />

      <div class="border-t h-px" />

      <MarketPlace />
    </div>

    <div class="border-l w-px" />

    <div class="flex flex-col gap-10 md:w-4.5/10">
      <Onboarding />
      
      <div class="border-t h-px" />

      <Loans />

      <div class="border-t h-px" />

      <Deals />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalDashboard',
  components: {
    Cards: () => import("./cards/Index"),
    Deals: () => import("./Deals"),
    Loans: () => import("./Loans"),
    MarketPlace: () => import("./MarketPlace"),
    Onboarding: () => import("./Onboarding"),
  },
  data() {
    return {
      selectedCard: null
    };
  },
  methods: {
    setSelectedCard(card) {
      this.selectedCard = card;
    }
  },
};
</script>
