<template>
  <div
    class="bg-blue-200 bg-opacity-25 min-h-full -mx-10 -mt-4 p-10"
  >
    <h1 class="font-bold mb-3 text-2xl">
      Dashboard
    </h1>

    <div class="flex items-center mb-5">
      <template v-for="(filter, filterIndex) in filters">
        <div
          :key="filterIndex"
          class="processor-filter-item"
          :class="{ active: currentFilter === filter }"
          @click.prevent="setFilter(filter)"
        >
          {{ filter.title }}
        </div>
      </template>
    </div>

    <form
      class="mb-16"
      @submit.prevent="getStats"
    >
      <div class="gap-3 inline-flex">
        <form-group
          type="date"
          v-model="form.data.start_date.value"
          class="inline-block text-base hover:opacity-100"
          :class="{ 'opacity-50': currentFilter }"
          @change="currentFilter = null"
        >From</form-group>
        <form-group
          type="date"
          v-model="form.data.end_date.value"
          class="inline-block text-base hover:opacity-100"
          :class="{ 'opacity-50': currentFilter }"
          @change="currentFilter = null"
        >To</form-group>
        <button
          type="submit"
          class="btn btn-blue btn-submit"
        >
          Refresh
        </button>
      </div>
    </form>

    <statistics
      :stats="stats"
      :groupable="true"
    />

  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'DailyStats',
  data() {
    return {
      currentFilter: null,
      filters: [
        { name: 'today', title: 'Today' },
        { name: 'yesterday', title: 'Yesterday' },
        { name: 'weekly', title: 'Past 7 Days' },
        { name: 'monthly', title: 'Past Month' },
        { name: 'all', title: 'All Time' }
      ],
      form: this.$options.basicForm(['start_date', 'end_date']),
      stats: this.$options.resource([]),
      request: null,
    };
  },
  computed: {
    date: () => new Date().toDateString(),
    groups() {
      const units = this.stats.data.map(stat => stat.unit)
        .filter((unit, index, array) => array.indexOf(unit) === index);
      return units.map(unit => ({
        title: unit,
        stats: this.stats.data.filter(stat => stat.unit === unit),
      }));
    },
  },
  beforeMount() {
    this.setFilter(this.filters[0]);
  },
  methods: {
    setFilter(filter) {
      const startDate = new Date();
      const endDate = new Date();
      this.currentFilter = filter;
      switch (filter.name) {
        case 'yesterday':
          startDate.setDate(startDate.getDate() - 1);
          endDate.setDate(endDate.getDate() - 1);
          break;
        case 'weekly':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case 'monthly':
          startDate.setMonth(startDate.getMonth() - 1);
          break;
      }
      this.form.data.start_date.value = startDate?.toDateString();
      this.form.data.end_date.value = endDate?.toDateString();
      this.getStats();
    },
    async getStats() {
      const getAll = this.currentFilter?.name === 'all';
      this.request?.cancel();
      const axiosSource = axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      this.stats.error = false;
      this.stats.loading = true;
      const data = getAll ? { filter: 'all' } : this.getFormData();
      await this.sendRequest('admin.statistics.daily', data, {
        axiosProps: {
          cancelToken: axiosSource.token,
        },
        success: (response) => {
          const { data } = response.data;
          this.stats.setValue(data);
        },
        error: (error) => {
          console.log(error);
          this.stats.error = error;
          console.log(error);
        },
      });
      this.stats.loading = false;
    },
  },
};
</script>